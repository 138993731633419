import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdVerifiedUser,
  MdGroup,
  MdAddHomeWork,
  MdBusiness

} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import TrainerDashboard from "views/admin/trainer_default";
import UserManagement from "views/admin/usermanagement";
import ExpenseManagement from "views/admin/expensemanagement"
import AllPayment from "views/admin/payment";
import AssignedUsers from "views/admin/assignedUsers";
import UserPlan from "views/admin/users_plan";
import TrainerPlan from "views/admin/trainer_plan";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import StaffManagement from 'views/admin/staff_management'
import RoleManagement from 'views/admin/roles'
import PermissionManagement from 'views/admin/permissions'
import BlogPost from 'views/admin/blog'
import CheckIn from 'views/admin/checkin'
import ClassTable from 'views/admin/class'
import Schedules from 'views/admin/schedules'
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signup";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    authorization:[2,3,4,6,7]
  },
  {
    name: "AssignedUsers",
    layout: "/admin",
    path: "/trainer/user",
    icon: <Icon as={MdGroup} width='20px' height='20px' color='inherit' />,
    component: AssignedUsers,
    authorization:[2,3,4,6,7]
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: (
      <Icon
        as={MdGroup}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: UserManagement,
    secondary: true,
    children: [
      {
        name: "All Users",
        layout: "/admin",
        path: "/users/allusers",
        component: UserManagement,
        authorization:[2,3,4]
      },
      // {
      //   name: "Create User",
      //   layout: "/admin",
      //   path: "/nft-marketplace/create",
      //   component: DataTables,
      // },
    
    ],
    authorization:[2,3,4]
  },
  {
    name: "Available Plans",
    layout: "/admin",
    path: "/plan",
    icon: (
      <Icon
        as={MdBusiness}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: UserPlan,
    secondary: true,
    children: [
      {
        name: "User's Plan",
        layout: "/admin",
        path: "/plan/available-plan-users",
        component: UserPlan,
        authorization:[2,3,4]
      },
      {
        name: "Trainer's Plan",
        layout: "/admin",
        path: "/plan/available-plan-trainer",
        component: TrainerPlan,
        authorization:[2,3,4]
      },],
      authorization:[2,3,4]
  },
  {
    name: "Staff Management",
    layout: "/admin",
    path: "/staff",
    icon: (
      <Icon
        as={MdGroup}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: StaffManagement,
    secondary: true,
    children: [
      {
        name: "Staff",
        layout: "/admin",
        path: "/staff/allstaffs",
        component: StaffManagement,
        authorization:[2,3,4]
      }
      ],
      authorization:[2,3,4]
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    icon: (
      <Icon
        as={MdGroup}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: StaffManagement,
    secondary: true,
    children: [
      {
        name: "Role",
        layout: "/admin",
        path: "/settings/roles",
        component: RoleManagement,
        authorization:[2]
      },
      {
        name: "Permission",
        layout: "/admin",
        path: "/settings/permissions",
        component: PermissionManagement,
        authorization:[2]
      }
      ],
      authorization:[2]
  },
  {
    name: "Blog",
    layout: "/admin",
    path: "/blogpost",
    icon: (
      <Icon
        as={MdGroup}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: BlogPost,
    secondary: true,
    authorization:[2,3,4,6]
    
   
  },
  {
    name: "Checkin-Users",
    layout: "/admin",
    path: "/checkin",
    icon: (
      <Icon
        as={MdGroup}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: CheckIn,
    secondary: true,
    authorization:[2,3,4,6]
   
  },
  {
    name: "Class Table",
    layout: "/admin",
    path: "/class",
    icon: (
      <Icon
        as={MdGroup}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: ClassTable,
    secondary: true,
    authorization:[2,3,4]
  },
  {
    name: "Schedules",
    layout: "/admin",
    path: "/schedules",
    icon: (
      <Icon
        as={MdGroup}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Schedules,
    secondary: true,
    authorization:[2,3,4,6]
  },
  {
    name: "Expense Management",
    layout: "/admin",
    path: "/expense/management",
    icon: (
      <Icon
        as={MdGroup}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component:ExpenseManagement ,
    secondary: true,
    authorization:[2,3,4,6]
  },
  {
    name: "All Payments",
    layout: "/admin",
    path: "/allpayments",
    icon: (
      <Icon
        as={MdGroup}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: AllPayment,
    secondary: true,
    authorization:[2]
   
  },
  
  // {
  //   name: "Plan",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Classes",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Shop",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Blog",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
    authorization:[2,3,4,6]
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    authorization:[2,3,4,6]
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignUpCentered,
    authorization:[2,3,4,6]
  },
  // {
  //   name: "Logout",
  //   layout: "/auth",
  //   path: "/logout",
  //   icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
  //   component: SignUpCentered,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export default routes;
