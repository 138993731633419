/*
 ************
 * BASE URLhhgjhgj *
 ************
*/
export const LOGIN_PAGE = "http://localhost:3000/login";
export const MAIN_MENU = "http://localhost:3000/home";
//export const API_BASE_URL = "http://127.0.0.1:80";
export const API_BASE_URL="https://elitefitnessapi.elitefitnessclub.ng"

//Company

/*
 **************************
 * ELITE Specific Endpoints *
 ***************************
*/
export const GET_ALL_ROLES = '/roles'
export const GET_ALL_PERMISSION = '/permissions'
export const CREATE_ADMINER = '/createadmin'
export const ADMIN_LOGIN = 'adminlogin'

export const GET_ALL_USERS = '/getAllUsers'
export const GET_USER_INFORMATION= '/getUserInformation'

export const GET_AVAILABLE_PLANS = '/availableplans'
export const SAVE_NEW_PLAN = '/availableplans'

export const GET_AVAILABLE_TRAINER_PLANS = '/trainerplans'
export const SAVE_NEW_TRAINER_PLAN = '/trainerplans'

export const STAFF = '/staff'

export const BLOG_POST = '/blogpost'

export const CREATE_USERS = '/createUser'

export const CHECKIN = '/checkin' 
export const GET_CHECK_IN = '/get-checkin'

export const SCHEDULES = '/schedules'

export const GET_ALL_TRAINER='/get-all-trainers'

export const UPDATE_EXPIRY_DATE = '/update_expiry_date'

export const GET_DASHBOARD_CORE = '/dashboardCore'
export const GET_ALL_PAYMENT = '/allpayment'
export const GET_ALL_EXPENSE = '/allexpenses'

export const CLASSES = '/classes'
export const GET_ASSIGNED_USERS_TRAINER = '/get_assigned_user_trainers'
