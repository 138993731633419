import {
  Avatar,
  Box,
  Button,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Drawer,
   DrawerOverlay,
   DrawerContent,
    DrawerHeader, 
    Tooltip,
    IconButton,
    DrawerBody,Tag,Input,FormControl,FormLabel,Checkbox,Select,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,InputRightElement,InputLeftElement,InputGroup,HStack
} from "@chakra-ui/react";
import React, { useMemo, useState,useRef } from "react";

import {
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  SearchIcon,CloseIcon
} from "@chakra-ui/icons";

import {MdVisibility} from "react-icons/md";
import UserBanner from './UserBanner.js'
import General from './General.js'
import Plan from './Plan.js'
import Payment from './Payment.js'
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import { message } from "antd";
import { useSelector } from "react-redux";
import { saveRecords,fetchRecords } from "appRedux/actions";
import {CREATE_USERS, GET_ALL_PAYMENT, GET_AVAILABLE_PLANS, GET_AVAILABLE_TRAINER_PLANS, GET_USER_INFORMATION} from '../../../../constants/ServerUrl.js'
import moment from "moment";
function TopCreatorTable(props) {
  const { columnsData, tableData,refresh,paymentStatus } = props;
  console.log(tableData,columnsData)
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const {token} = useSelector((state)=>state.UserDetails.userdetails)
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const[personalInformation,setPersonalInformation] = useState([])
  const[planInformation,setPlanInformation] = useState([])
  const[paymentInformation,setPaymentInformation] = useState([])
  const [loading,isLoading] = React.useState(true)
  const [formValues, setFormValues] = React.useState({});
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [isChecked,setIsChecked] = useState(false)
  const [isApplication,setisApplication] = useState(false)
  const [isCoach,setIsCoachChecked] = useState(false)
  const [duration,setDuration] = React.useState([])
  const [durationCoach,setCoachDuration] = React.useState([])
  const {userdetails} = useSelector((state)=>state.UserDetails)
  let role_id = userdetails?.user?.roles[0].id;
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow,canPreviousPage,canNextPage,pageOptions,pageCount,gotoPage,nextPage,previousPage,setPageSize,state:{pageIndex,pageSize,globalFilter},setGlobalFilter,preGlobalFilteredRows } =
    tableInstance;
   

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.900", "white");
  const inputRef = useRef();
  const [searchValue, setsearchValue] = useState(globalFilter);
  const onSearchInputChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  const closeDrawer = () => {
    
    setIsDrawerOpen(false);
    setSelectedRowData(null)
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleApplicationFee = (event)=>{
    setisApplication(event.target.checked)
  }
  const handleCoachCheckboxChange = (event) => {
    setIsCoachChecked(event.target.checked);
  };
  const getPlanDuration= ()=>{
    fetchRecords(GET_AVAILABLE_PLANS,token).then((res) => {
     
     setDuration(res.data.availableplan)
      
   }).catch(err => {
        message.error('Fetch Plan Failed')
       console.log("error",err);
   });
   }
   const getPlanCoachDuration= ()=>{
    fetchRecords(GET_AVAILABLE_TRAINER_PLANS,token).then((res) => {
     
     setCoachDuration(res.data.trainerplan)
      
   }).catch(err => {
        message.error('Fetch Trainer Plan Failed')
       console.log("error",err);
   });
   }


  React.useEffect(async()=>{
    if(isDrawerOpen){
      getPlanDuration()
      getPlanCoachDuration()
      getPersonalInformation(selectedRowData?.userId,selectedRowData?.email)
     
    }
   
  },[isDrawerOpen])


  const viewUser = (data)=>{
      
      setSelectedRowData(data);
      setIsDrawerOpen(true);
     
    }
    const createUser=()=>{
      setIsDrawerOpen(true)
    }
    const getPersonalInformation= (id,email)=>{
      isLoading(true)
      
      saveRecords(GET_USER_INFORMATION, {userid:id,email},token).then(res => {
        console.log(res)
        isLoading(false);
        if (res.status == "Request was successful") {
          
            setPersonalInformation(res.data.personalInformation)
            setPlanInformation(res.data.planInformation)
            setPaymentInformation(res.data.paymentInformation)  
            message.info('Data Successfully Retrieved')
        } else {
            // setError(res.error);
            message.info("Error encountered while retrieving userinformation");
        }
        
    }).catch(err => {
       console.log('gugu',err)
        if(err?.response?.data){
          console.log(err?.response?.data)
            message.error(err?.response?.data?.message)
            
        }
       console.log('hghghjg')
        isLoading(false);
    });
    }


    const CreateUser = ()=>{
    
  
      const mergedObject = formValues
      
      const hasFirstname = mergedObject.hasOwnProperty("firstname");
      const hasLastname = mergedObject.hasOwnProperty("lastname");
      const hasEmail = mergedObject.hasOwnProperty("email");
      if(!isApplication){
        message.error('Application Fee  is Required')
        return false
      }
      
      if(!hasFirstname){
        message.error('Firstname is Required')
        return false
      }
      if(!hasLastname){
        message.error('Lastname is Required')
        return false
      }
      if(!hasEmail){
        message.error('Email is Required')
        return false
      }
      if(!isChecked){
        delete mergedObject?.plan_id;
        delete mergedObject?.plan_duration;
        delete mergedObject?.plan_selected
        delete mergedObject?.plan_amount
      }
      if(!isCoach){
        delete mergedObject?.coach_id;
        delete mergedObject?.coach;
        delete mergedObject?.coach_duration
        delete mergedObject?.coach_amount
      }
      if(isChecked && mergedObject.hasOwnProperty("plan_id")){
        const x = duration.filter((x)=> x.id == mergedObject.plan_id)

        mergedObject.plan_duration = x[0]?.plan_duration
        mergedObject.plan_selected=x[0]?.plan_title
        mergedObject.plan_amount=x[0]?.plan_amount
      }
      if(isCoach && mergedObject.hasOwnProperty("coach_id")){
        const y = durationCoach.filter((x)=> x.id == mergedObject.coach_id)
        console.log(durationCoach,mergedObject.coach_id)
        console.log(y)
        mergedObject.coach = "YES"
        mergedObject.coach_duration = y[0]?.plan_duration
        mergedObject.coach_amount = y[0]?.plan_amount
      }
     
  
    
      let formData = new FormData();
       // let data = Helpers.objectFormBuilder(form, mergedObject);
      formData.append('firstname',mergedObject.firstname)
      formData.append('lastname',mergedObject.lastname)
      formData.append('email',mergedObject.email)
      if(isChecked && mergedObject.hasOwnProperty("plan_id")){
        formData.append('plan_duration',mergedObject.plan_duration)
        formData.append('plan_amount',mergedObject.plan_amount)
        formData.append('plan_selected',mergedObject.plan_selected)
      }
      if(isChecked && mergedObject.hasOwnProperty("coach_id")){
        formData.append('coach',mergedObject.coach)
        formData.append('coach_amount',mergedObject.coach_amount)
        formData.append('coach_duration',mergedObject.coach_duration)
      }
      isLoading(true);

        // return false;
      saveRecords(GET_ALL_PAYMENT, formData,token).then(res => {
        console.log(res)
        isLoading(false)
        if (res.status == "Request was successful") {
           
          message.success('User Successfully Created')  
          //message.success(editIcon ? 'Staff update was successful':'Staff creation was successful')
            setFormValues({})
            
            closeDrawer()
            
        } else {
            // setError(res.error);
            message.info("Error encountered while creating adminer");
        }
        isLoading(false);
    }).catch(err => {
        console.log(err?.response?.data);
        if(err?.response?.data){
            message.error(err?.response?.data)
        }
       
        isLoading(false);
    });
    }

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'
          mb='10px'
          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
          <Text color={textColor} fontSize='xl' fontWeight='600'>
            Assigned Users
          </Text>
          {/* <Button variant='action' onClick={()=>createUser()}>Create New User</Button> */}
        </Flex>
        <Box mb={"20px"} ml={'10px'} width={'sm'} alignSelf={"flex-end"}>
          
          
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              ref={inputRef}
              type="text"
              value={searchValue || ""}
              onChange={(e) => {
                setsearchValue(inputRef.current.value);
                onSearchInputChange(inputRef.current.value);
              }}
              placeholder={`Search User`}
            />
            {searchValue && (
              <InputRightElement
                cursor={"pointer"}
                children={
                  <CloseIcon
                    fontSize={14}
                    _hover={{ color: "gray.600" }}
                    color="gray.300"
                  />
                }
                onClick={() => {
                  setGlobalFilter("");
                  setsearchValue("");
                }}
              />
            )}
          </InputGroup>
        </Box>
        <HStack spacing={4} marginBottom={'3'} marginLeft={'3'}>
          {
            role_id == 7 && <Tag size={'md'}  variant='solid' colorScheme={paymentStatus ? 'green': 'red'}>
            {paymentStatus ? 'Remitted' : 'Not Remitted' }
            </Tag>
          }
          {
            role_id == 2  && <Button marginLeft={'4'} colorScheme={paymentStatus ? 'green': 'red'}>{paymentStatus ? 'Reverse': 'Remit'}</Button>
          }
            
              
         </HStack>
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='secondaryGray.700'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell, index) => {
                    let data = "";

                   
                   
                    if (cell.column.Header === "First Name") {
                      data = (
                        <Flex align='center'>
                          <Avatar
                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80"
                            w='30px'
                            h='30px'
                            me='8px'
                          />
                          <Text
                            color={textColor}
                            fontSize='sm'
                            fontWeight='600'>
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } 
                    else if (cell.column.Header === "SN") {
                      data = (
                        <Tag size="md" display="flex" justifyContent="center" alignItems="center">
                          {rowIndex + 1}
                        </Tag>
                      );
                     
                    }
                    
                  else if (cell.column.Header === "Status") {
                      data = (
                        <Box>
                         <Tag size="md" variant="outline" colorScheme='green'>Verified</Tag>
                        </Box>
                      );
                    }
                    else if (cell.column.Header === "Expiry Date") {
                      const today = moment();
                      const expiry = moment(cell.value);
                      const daysLeft = expiry.diff(today, 'days');
                      let textColor=""
                      if (daysLeft > 5) {
                        textColor = 'green'; // Set the color to 'green' if the plan is still active
                      } else if (daysLeft <= 5 && daysLeft >= 0) {
                        textColor = 'yellow'; // Set the color to 'warning' if the date is within 5 days
                      } else if (daysLeft < 0) {
                        textColor = 'red'; // Set the color to 'red' if the date has already passed
                      }
                      data = (
                        
                        <Box>
                         <Tag size="sm" variant="outline" colorScheme={textColor}>{moment(cell.value).format('DD-MM-YYYY')}</Tag>
                        </Box>
                      );
                    }
                    else if (cell.column.Header === "Action") {
                      data = (
                        <Box onClick={()=>viewUser(row.original)}>
                          <MdVisibility/>
                        </Box>
                      );
                    }
                  
                    else  {
                      data = (
                        <Text
                          color={textColorSecondary}
                          fontSize='sm'
                          fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } 
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text flexShrink="0" mr={8}>
            Page{" "}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>{" "}
            of{" "}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>
          <Text flexShrink="0">Go to page:</Text>{" "}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={pageOptions.length}
            onChange={(value) => {
              const page = value ? value - 1 : 0;
              gotoPage(page);
            }}
            defaultValue={pageIndex + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Next Page">
            <IconButton
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              onClick={() => gotoPage(pageCount - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex>

        <Drawer isOpen={isDrawerOpen} placement="right" onClose={closeDrawer} size="sm">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>{!selectedRowData  && 'Create new User'}</DrawerHeader>
            <DrawerBody>
              
             {selectedRowData && Object.keys(selectedRowData).length > 0 &&
             <>
                     <UserBanner
              gridArea='1 / 1 / 2 / 2'
              banner={banner}
              avatar={avatar}
              name={selectedRowData?.firstname +' '+selectedRowData?.lastname}
              job='Gym'
              checkin='17'
              schedules='9.7k'
              plan='274'
            />
            
            <General
              gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
              minH='365px'
              pe='20px'
              loading={loading}
              data={personalInformation}
            />
           
            <Payment
              gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
              minH='365px'
              pe='20px'
              loading={loading}
              data={paymentInformation}
            />
             
             </> }

             {
              !selectedRowData && <>
              
              <FormControl>
                <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Firstname<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  value={formValues.firstname || ''}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='First name'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  onChange={(event)=>setFormValues({...formValues,firstname:event.target.value})}
                />

<FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Lastname<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  value={formValues.lastname || ''}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='Last name'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  onChange={(event)=>setFormValues({...formValues,lastname:event.target.value})}
                />
              </FormControl>

              <FormLabel
                  display='flex'
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'
                  color={textColor}
                  mb='8px'>
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                
                  isRequired={true}
                  value={formValues.email || ''}
                  variant='auth'
                  fontSize='sm'
                  ms={{ base: "0px", md: "0px" }}
                  type='email'
                  placeholder='Email address'
                  mb='24px'
                  fontWeight='500'
                  size='lg'
                  onChange={(event)=>setFormValues({...formValues,email:event.target.value})}
                />
                <Checkbox 
                  isChecked={isApplication}
                  onChange={handleApplicationFee} defaultChecked>Application Free @5000
                </Checkbox><br/>

                <Checkbox
                mt={10} 
                isChecked={isChecked}
                onChange={handleCheckboxChange}>Create User plan</Checkbox>

                {
                  isChecked && <>
                  <Select
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    placeholder="Select an option"
                    mt="24px"
                    mb="24px"
                    value={formValues.plan_id || ''}
                    fontWeight="500"
                    size="lg"
                    onChange={(event)=>setFormValues({...formValues,plan_id:event.target.value})}
                  >
                  {duration && duration.length > 0 && duration.map((d,index)=>{
                    return <option value={d.id}>{d.plan_title} --- {d.plan_duration}month(s) @ {d.plan_amount}</option>
                  })}
                </Select>
                <Checkbox 
                isChecked={isCoach}
                mb={5} 
                onChange={handleCoachCheckboxChange}>Select Coach Plan</Checkbox>
                {
                  isCoach && <Select
                              isRequired={true}
                              variant="auth"
                              fontSize="sm"
                              ms={{ base: "0px", md: "0px" }}
                              placeholder="Select an option"
                              mt="5px"
                              mb="24px"
                              value={formValues.coach_id || ''}
                              fontWeight="500"
                              size="lg"
                              onChange={(event)=>setFormValues({...formValues,coach_id:event.target.value})}
                            >
                            {durationCoach && durationCoach.length > 0 && durationCoach.map((d,index)=>{
                              return <option value={d.id}>{d.plan_amount} --- {d.plan_duration}month(s) @ {d.plan_amount}</option>
                            })}
                          </Select>
                }
                


        </>
                }

              <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              loadingText="Please wait"
              isLoading={loading}
              mb='24px' onClick={CreateUser}>
              Create User
            </Button>
              
              </>

             }
         
            
           
           
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      </Flex>
    </>
  );
}

export default TopCreatorTable;
